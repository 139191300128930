<template>
  <b-card :class="cardClasses" body-class="p-0">
    <template #header>
      <card-header
        :title="title"
        collapsible
        :collapse-id="id"
      />
    </template>
    <b-collapse :id="id" :visible="!startCollapsed">
      <div class="p-3">
        <slot name="body"></slot>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>

const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'CardCollapsible',
  components: { CardHeader },
  props: {
    title: String,
    id: String,
    startCollapsed: Boolean,
    addedClasses: String,
  },
  data() {
    return {
      isCollapsed: this.startCollapsed,
    };
  },
  computed: {
    cardClasses() {
      const a = this.addedClasses;
      if (this.isCollapsed) return `${a} card--is-collapsed`;
      return a;
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      // console.log('collapseId:', collapseId);
      // console.log('isJustShown:', isJustShown);
      this.isCollapsed = !isJustShown;
    });
  },
};
</script>

<style scoped>

</style>
